(function () {
  'use strict';

  class ModuleSetCtrl {
    constructor(ModuleSet, $mdToast) {
      let vm = this;
      vm.ModuleSet = ModuleSet;
      vm.isLoading = false;
      vm.module_set = {
        module_categories: [{
          field_type: 'text'
        }]
      };
      vm.$mdToast = $mdToast;
    }
    addCategory() {
      const vm = this;
      vm.module_set.module_categories.push({
        field_type: 'text'
      });
    }
    removeItem(module_categories, index) {
      module_categories.splice(index, 1);
    }
    submitModuleSet(form) {
      let vm = this;
      if (form.$valid) {
        vm.isLoading = true;
        vm.ModuleSet.moduleSetCreate(vm.module_set)
          .then(()=> {
            vm.message();
          })
          .catch((response) => {
            const {data: {
              error
            }} = response;
            angular.forEach(error, function (v) {
              vm.message(v.join(' '));
            });
          })
          .finally(() => {
            vm.isLoading = false;
          });
      }
    }
    message(msg = 'Module Set Created Successfully') {
      const vm = this;
      vm.$mdToast.show(
        vm.$mdToast.simple()
          .textContent(msg)
          .position('bottom right')
          .hideDelay(3000)
      );
    }
  }

  /**
   * @ngdoc object
   * @name learning.moduleSet.controller:ModuleSetCtrl
   *
   * @description
   *
   */
  angular
    .module('learning.moduleSet')
    .controller('ModuleSetCtrl', ModuleSetCtrl);
}());
